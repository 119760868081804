import React from "react";
import "./ferti.css";
import { Navbar, Fcontact } from "../../components/";

const Ferti = () => {
  return (
    <div className="app">
      <Navbar />
      <div className="g__ferti-header">
        <h1 className="g__ferti-title">Fertilisation 100% naturel</h1>
      </div>
      <div className="g__ferti-con">
        <div className="g__ferti-para1">
          <p>
            Les programmes de fertilisation Gazon Écologique font beaucoup plus
            que nourrir la pelouse : ils stimulent l'activité biologique et
            améliorent la structure du sol. Tous les programmes offerts sont
            élaborés à partir d'une sélection de fertilisants et d'amendements
            haut de gamme et 100% naturel.
          </p>
        </div>
        <Fcontact />
      </div>
    </div>
  );
};

export default Ferti;
