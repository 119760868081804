import React from "react";
import "./nettoyage.css";
import { Navbar, Fcontact } from "../../components/";

const Nettoyage = () => {
  return (
    <div className="app">
      <Navbar />
      <div className="g__nettoyage-header">
        <h1 className="g__nettoyage-title">Nettoyage printanier</h1>
      </div>
      <div className="g__nettoyage-con">
        <div className="g__nettoyage-para1">
          <div style={{ marginBottom: "2rem" }}>
            <span style={{ fontWeight: "800" }}> Le nettoyage printanier:</span>{" "}
            Le printemps est le moment idéal pour redonner vie à votre espace
            extérieur. Chez Gazon Écologique, nous sommes là pour vous aider à
            préparer votre terrain pour une saison estivale éclatante. Notre
            service de nettoyage printanier est conçu pour éliminer les débris
            tels que les roches, les feuilles mortes, les branches, le gazon
            mort tout en revitalisant et aérant votre gazon, afin de créer un
            environnement propice à la croissance saine de votre pelouse.
          </div>
          <div style={{ marginBottom: "2rem" }}>
            Nous utilisons des équipements spécialisés, tels que des balais
            mécaniques et des souffleurs industriels, pour éliminer efficacement
            les feuilles mortes, les branches et autres débris accumulés pendant
            l'hiver. Notre équipe expérimentée veille à ce que votre espace
            extérieur retrouve son éclat naturel, tout en préservant la santé de
            votre gazon. En préparant la surface du gazon dès le printemps, nous
            créons les conditions optimales pour une croissance vigoureuse et un
            gazon luxuriant tout au long de la saison. Notre objectif est de
            vous offrir un espace extérieur propre, accueillant et prêt à être
            apprécié pleinement.
          </div>
          <div style={{ marginBottom: "2rem" }}>
            Confiez votre nettoyage printanier à l'équipe de professionnels de
            Gazon écologique et profitez d'un jardin impeccable dès les premiers
            jours du printemps. Contactez-nous dès aujourd'hui pour planifier
            votre service de nettoyage printanier et offrez à votre espace
            extérieur le soin qu'il mérite.
          </div>
        </div>
        <Fcontact />
      </div>
    </div>
  );
};

export default Nettoyage;
