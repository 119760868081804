import React from "react";
import "./fcontact.css";

const Fcontact = () => {
  return (
    <div className="g__fcontact">
      <h6 className="g__fcontact-t">
        Communiquer dès maintenant avec nous par téléphone ou par courriel pour
        obtenir plus de renseignements et obtenir votre soumission gratuite.
      </h6>
      <a href="/contact">
        <button className="g__button">Contactez-nous!</button>
      </a>
    </div>
  );
};

export default Fcontact;
