import React, { useState } from "react";
import "./form.css";
import emailjs from "@emailjs/browser";

const Form = () => {
  // State hooks for managing confirmation and error messages
  const [emailSent, setEmailSent] = useState(false);
  const [emailError, setEmailError] = useState(false);

  function sendEmail(e) {
    e.preventDefault();

    setEmailError(false);

    emailjs
      .sendForm(
        "service_r1jvtik",
        "template_1fcq1jk",
        e.target,
        "NKFFodKiUnK5ZJ-RE"
      )
      .then((res) => {
        console.log(res);
        setEmailSent(true);
      })
      .catch((err) => {
        console.log(err);
        setEmailError(true);
      });
  }

  if (emailSent) {
    return (
      <div className="g__form">
        <p style={{ margin: "1rem" }}>
          Votre message a été envoyé avec succès!
        </p>
      </div>
    );
  } else {
    return (
      <div className="g__form">
        <form onSubmit={sendEmail}>
          <h2>Contactez nous!</h2>
          <input type="text" id="name" name="name" placeholder="Nom" required />
          <input
            type="email"
            id="email"
            name="user_email"
            placeholder="Courriel"
            required
          />
          <input
            type="text"
            id="phone"
            name="phone"
            placeholder="Numéro de téléphone"
            required
          />
          <input
            type="text"
            id="adresse"
            name="adresse"
            placeholder="Adresse"
            required
          />
          <textarea
            id="message"
            rows="4"
            name="message"
            placeholder="Votre message"
          ></textarea>
          <button type="submit">Envoyer</button>
        </form>
        {emailError && (
          <div className="g__form" style={{ margin: "1rem" }}>
            <p>Erreur lors de l'envoi de votre message. Veuillez réessayer.</p>
          </div>
        )}
      </div>
    );
  }
};

export default Form;
