import React from "react";
import "./contact.css";
import { Navbar, Form } from "../components/";

const Contact = () => {
  return (
    <div className="app">
      <Navbar />
      <div className="first">
        <Form />
        <div className="first1"></div>
        <div className="first2"></div>
      </div>
    </div>
  );
};

export default Contact;
