import React from "react";
import "./terreau.css";
import { Navbar, Fcontact } from "../../components/";

const Terreau = () => {
  return (
    <div className="app">
      <Navbar />
      <div className="g__terreau-header">
        <h1 className="g__terreau-title">Terreautage au compost</h1>
      </div>
      <div className="g__terreau-con">
        <div className="g__terreau-para1">
          <div className="g__terreau-p1">
            Le terreautage consiste à appliquer directement sur la pelouse une
            couche de compost ou de terreau. L'épaisseur de cette couche varie
            selon les besoins. Généralement, on recommande une épaisseur
            d'environ 1/2 à 1 cm.
          </div>
          <div className="g__terreau-p">
            Le terreautage au compost offre plusieurs avantages que l'on
            retrouve séparément dans les autres méthodes de fertilisation et
            d'entretien de la pelouse.
          </div>
          <h6 className="g__terreau-h6">Fertilisation</h6>
          <div>
            Au niveau de la fertilisation, l'application d'une seule couche de
            compost comble de 50 à 100 % des besoins en fertilisation annuelle
            d'une pelouse. Le compost est le seul amendement à dégagement lent
            qui contient tous les éléments nutritifs nécessaires et ce, à un
            niveau balancé :
          </div>
          <div className="g__terreau-enumcon">
            <div className="g__terreau-enum">
              - <span className="g__terreau-span">Macro-éléments</span>, azote,
              phosphore et potassium;
            </div>
            <div className="g__terreau-enum">
              - <span className="g__terreau-span">Micro-éléments</span>, fer,
              soufre, manganèse;
            </div>
            <div className="g__terreau-enum">
              - <span className="g__terreau-span">Oligo-éléments</span>, cuivre,
              bore.
            </div>
          </div>
          <h6 className="g__terreau-h6">Structure du sol</h6>
          <div>
            La structure du sol est extrêmement importante. Elle est directement
            reliée au rapport sol-air-eau-gazon. Le compost améliore la
            structure du sol. Un sol bien structuré va mieux retenir l'eau et
            les éléments fertilisants. L'apport de compost permettra au sol
            d'être plus poreux favorisant ainsi, les échanges gazeux et le
            développement de nouvelles racines, de radicelles et de stolons.
          </div>
          <div className="g__terreau-p">
            L'application de compost stimule l'activité microbienne dans le sol.
            L'activité microbienne est particulièrement importante pour la santé
            de la pelouse. Si la pelouse à été traitée avec des pesticides et
            des engrais de synthèse, l'activité microbienne est faible, quasi
            inexistante.
          </div>
          <div className="g__terreau-p">
            Ce sont les micro-organismes présents dans le compost qui vont
            transformer le chaume en matière organique assimilable par la
            pelouse.
          </div>
          <div className="g__terreau-p">
            La matière organique contenue dans le compost (environ 60%), agit
            comme un tampon qui produit un effet neutralisant sur le pH
          </div>
          <div className="g__terreau-p1" style={{ paddingTop: "1.25rem" }}>
            C'est l'ensemble de ces propriétés qui nous permet d'affirmer qu'un
            terreautage constitue la première étape d'un programme d'entretien
            écologique complet et professionnel. Cette opération corrige
            plusieurs problèmes directement à leurs sources et permet d'obtenir
            un sol et une pelouse en santé.
          </div>
        </div>
        <Fcontact />
      </div>
    </div>
  );
};

export default Terreau;
