import React from "react";
import "./ense.css";
import { Navbar, Fcontact } from "../../components/";

const Ense = () => {
  return (
    <div className="app">
      <Navbar />
      <div className="g__ense-header">
        <h1 className="g__ense-title">Ensemencement au compost</h1>
      </div>
      <div className="g__ense-con">
        <div className="g__ense-para1">
          <div className="g__ense-p1">
            Efficace, écologique et économique, cette méthode permet d'obtenir
            une pelouse dense et uniforme. À partir de semences certifiées, nous
            élaborons un mélange écologique en fonction des caractéristiques de
            votre terrain. Gazon Écologique garantit les résultats des
            ensemencements, c'est-à-dire, l'établissement d'une pelouse dense et
            uniforme. Une pelouse établie par semis (ensemencement) est moins
            coûteuse, plus durable et plus écologique que du gazon en plaque.
          </div>
          <div className="g__ense-p">
            - <span className="g__ense-span">Plus durable</span> :
            L'ensemencement est réalisé avec des semences qui tiennent compte
            des cinq éléments : zone de rusticité du site; intensité de
            l'ensoleillement, degré d'humidité du sol; nature du sol et type
            d'utilisation de la pelouse. Notez que le gazon en plaque est
            composé uniquement de pâturin du Kentucky, variété très exigeante et
            eau et fertilisation;
          </div>
          <div className="g__ense-p">
            - <span className="g__ense-span">Plus écologique</span> : En
            utilisant des semences adaptées, on réduit les besoins de
            fertilisation. On peut aussi utiliser des mélanges endophytes et
            résistants à la sécheresse. De plus, aucun engrais de synthèse n'est
            utilisé.
          </div>
        </div>
        <Fcontact />
      </div>
    </div>
  );
};

export default Ense;
