import React, { useState } from "react";
import "./navbar.css";
import { BsList, BsX, BsChevronDown } from "react-icons/bs";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [subMenu, setSubMenu] = useState(false);

  const Menu = () => (
    <>
      <a href="/">Accueil</a>
      <NavItem icon="Services">
        <DropdownMenu />
      </NavItem>
      {/* <a href="/forfaits">Forfaits</a> */}
      <a href="/a-propos">Réalisations</a>
      <a href="/contact">Contact</a>
    </>
  );

  function DropdownMenu() {
    return (
      <div className="dropdown">
        <a
          style={{ paddingBottom: "0.75rem" }}
          className="menu-item"
          href="/services/nettoyage/"
        >
          Nettoyage printanier
        </a>
        <a className="menu-item" href="/services/nettoyage-stationnement/">
          Nettoyage stationnement
        </a>
        <a className="menu-item" href="/services/fertilisation/">
          Fertilisation
        </a>
        <a className="menu-item" href="/services/terreautage/">
          Terreautage
        </a>
        {/* <a className="menu-item" href="/services/tonte/">
          Tonte
        </a> */}
        <a className="menu-item" href="/services/ensemencement/">
          Ensemencement
        </a>
        <a className="menu-item" href="/services/epandage/">
          Épandage
        </a>
        <a className="menu-item" href="/services/aeration/">
          Aération
        </a>
        <a className="menu-item" href="/services/pailli/">
          Pailli
        </a>
        <a className="menu-item" href="/services/autre/">
          Autres Services
        </a>
      </div>
    );
  }

  function NavItem(props) {
    const [open, setOpen] = useState(false);

    return (
      <li className="nav-item">
        <a href="#" className="icon-button" onClick={() => setOpen(!open)}>
          {props.icon}
          <BsChevronDown
            size={15}
            style={{ top: "3px", left: "4px", position: "relative" }}
          />
        </a>
        {open && props.children}
      </li>
    );
  }

  const selectElement = (element) => {
    document.querySelector(element);
    selectElement("nav").addEventListener("click", () => {
      selectElement("nav").classList.toggle("active");
    });
  };
  return (
    <div className="g__navbar">
      <div className="g__navbar-contact">
        <div className="📞">
          <h4>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 16 16"
              className="navbarPhone"
              style={{ width: "1rem", height: "1rem", paddingTop: "0.25rem" }}
            >
              <path
                fillRule="evenodd"
                d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
              />
            </svg>
            Justin Côté : (819)580-1238 - David Bombardier : (873)200-1915
          </h4>
        </div>
        {/* <div className="🕘">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            viewBox="0 0 16 16"
            className="navbarClock"
          >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
          </svg>
          : Lun-Ven 9h-17h
        </div> */}
      </div>
      <div className="g__navbar-links">
        <a href="/">
          <div className="g__navbar-links_logo"></div>
        </a>
        <div className="g__navbar-links_container">
          <Menu />
        </div>
        <div className="g__navbar-menu">
          {toggleMenu ? (
            <BsX
              color="#00000"
              size={30}
              onClick={() => setToggleMenu(false)}
              style={{ display: "none" }}
            />
          ) : (
            <BsList
              color="#00000"
              size={27}
              onClick={() => setToggleMenu(true)}
            />
          )}
          {toggleMenu && (
            <div className="g__navbar-menu_container">
              <div className="g__navbar-menu_container-links">
                <nav>
                  <BsX
                    color="#00000"
                    size={30}
                    onClick={() => setToggleMenu(false)}
                    style={{
                      top: "2.75rem",
                      right: "1rem",
                      position: "fixed",
                      zIndex: "100",
                    }}
                  />
                  <ul className="nav-list">
                    <li>
                      <a href="/">Accueil</a>
                    </li>
                    <li>
                      <a
                        // style={{ left: "44vw" }}
                        onClick={() => setSubMenu(!subMenu)}
                      >
                        Services
                        <BsChevronDown
                          size={15}
                          style={{
                            top: "18px",
                            left: "4px",
                            position: "relative",
                          }}
                        />
                      </a>

                      <ul className={subMenu ? "sub-menu active" : "sub-menu"}>
                        <li>
                          <a href="/services/nettoyage/">
                            Nettoyage printanier
                          </a>
                        </li>
                        <li>
                          <a href="/services/nettoyage-stationnement/">
                            Nettoyage stationnement
                          </a>
                        </li>
                        <li>
                          <a href="/services/fertilisation/">Fertilisation</a>
                        </li>
                        <li>
                          <a href="/services/terreautage/">Terreautage</a>
                        </li>
                        {/* <li>
                          <a href="/services/tonte/">Tonte</a>
                        </li> */}
                        <li>
                          <a href="/services/ensemencement/">Ensemencement</a>
                        </li>
                        <li>
                          <a href="/services/epandage/">Épandage de chaux</a>
                        </li>
                        <li>
                          <a href="/services/aeration/">Aération</a>
                        </li>
                        <li>
                          <a href="/services/pailli/">Pailli</a>
                        </li>
                        <li>
                          <a href="/services/autre/">Autres services</a>
                        </li>
                      </ul>
                    </li>
                    {/* <li>
                      <a href="/forfaits">Forfaits</a>
                    </li> */}
                    <li>
                      <a href="/a-propos">Réalisations</a>
                    </li>
                    <li>
                      <a href="/contact">Contact</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
