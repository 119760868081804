import "./home.css";
import { Navbar } from "../components/";
import { Link } from "react-router-dom";
import Video from "../assets/clip-gazon-optimized.mp4";

const Home = () => {
  return (
    <div className="app">
      <Navbar />

      <div className="g__home-header">
        <div className="video-container">
          <video autoPlay muted loop playsInline>
            <source src={Video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>

          <div className="overlay-text">
            <h1 className="g__home-imgTitle">
              <span className="g__home-headerspan">Gazon Écologique</span>{" "}
              entretien de pelouse 100% écologique!
            </h1>
            <h2 className="g__home-imgTitle2">Depuis 2003</h2>
            <Link to="/contact">
              <button
                //onClick={() => window.open("/contact", "_blank")}
                className="buttonDevis"
              >
                Devis gratuit
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="g__home-div">
        <div className="g__home-div1">
          <h2 className="g__home-title1">
            La référence en entretien de gazon à Sherbrooke
          </h2>
          <div className="g__home-line"></div>
          <p className="g__home-para1">
            Gazon Écologique est une entreprise spécialisée dans l'entretien des
            espaces verts, principalement la pelouse. L'entreprise offre des
            solutions alternatives, sans produit chimique, en harmonie avec
            l'environnement. L'entreprise dessert les clientèles résidentielles,
            institutionnelles et commerciales.
          </p>
        </div>
        <div className="g__home-div2">
          <h2 className="g__home-title2">Secteur desservi</h2>
          <div className="g__home-para2">
            <div>Estrie</div>
          </div>
        </div>
      </div>

      <div className="g__home-div3">
        <h3 className="g__home-title3">Services</h3>
        <div className="g__home-services">
          <a href="/services/nettoyage">
            <div className="smlcon">
              <div className="g__home-servicecon1 service"></div>
              <h5>Nettoyage printanier</h5>
            </div>
          </a>
          <a href="/services/nettoyage-stationnement">
            <div className="smlcon">
              <div className="g__home-servicecon10 service"></div>
              <h5 style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}>
                Nettoyage stationnement
              </h5>
            </div>
          </a>
          <a href="/services/terreautage">
            <div className="smlcon">
              <div className="g__home-servicecon3 service"></div>
              <h5>Terreautage</h5>
            </div>
          </a>
          <a href="/services/ensemencement">
            <div className="smlcon">
              <div className="g__home-servicecon5 service"></div>
              <h5 style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}>
                Ensemencement écologique
              </h5>
            </div>
          </a>
          <a href="/services/fertilisation">
            <div className="smlcon">
              <div className="g__home-servicecon2 service"></div>
              <h5 style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}>
                Fertilisation 100% écologique
              </h5>
            </div>
          </a>

          {/* <a href="/services/tonte">
            <div className="smlcon">
              <div className="g__home-servicecon4"></div>
              <h9 className="h9">Tonte</h9>
            </div>
          </a> */}

          <a href="/services/epandage">
            <div className="smlcon">
              <div className="g__home-servicecon6 service"></div>
              <h5>Épandage de chaux</h5>
            </div>
          </a>
          <a href="/services/aeration">
            <div className="smlcon">
              <div className="g__home-servicecon7 service"></div>
              <h5>Aération</h5>
            </div>
          </a>
          <a href="/services/pailli">
            <div className="smlcon">
              <div className="g__home-servicecon8 service"></div>
              <h5>Paillis nutritifs</h5>
            </div>
          </a>
          <a href="/services/autre">
            <div className="smlcon">
              <div className="g__home-servicecon9 service"></div>
              <h5>Autres services</h5>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Home;
