import React from "react";
import "./propos.css";
import { Navbar } from "../components/";

const Propos = () => {
  return (
    <div className="app">
      <Navbar />
      <div className="g__propos-header">
        <h1 className="g__propos-title"> Nos réalisations</h1>
        <div className="g__propos-con">
          <div className="g__propos-div1">
            <div className="g__propos-div1-1"></div>
            <div className="g__propos-div1-2"></div>
          </div>
          <div className="g__propos-div1">
            <div className="g__propos-div2-1"></div>
            <div className="g__propos-div2-2"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Propos;
