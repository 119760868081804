import React from "react";
import "./epandage.css";
import { Navbar, Fcontact } from "../../components/";

const Pailli = () => {
  return (
    <div className="app">
      <Navbar />
      <div className="g__pailli-header">
        <h1 className="g__epandage-title">Paillis Nutritifs</h1>
      </div>
      <div className="g__epandage-con">
        <div className="g__epandage-para1">
          <div className="g__nettoyage-p1" style={{ marginBottom: "1rem" }}>
            Il est préférable de ne jamais laisser un sol à découvert. En plus
            d'être le meilleur moyen de contrôle des herbes indésirables,
            l'application de paillis assure une protection aux végétaux, limite
            l'érosion du sol et retient l'humidité.
          </div>
          <div>
            Gazon écologique est une compagnie spécialisée dans les couvres-sols
            écologique. C'est pourquoi nous offrons un service clé en main pour
            la pose de paillis nutritifs ou le rafraichissement de vos
            plates-bandes. Il nous fera grand plaisir de vous conseiller et de
            vous accompagner lors de vos projet printanier.
          </div>
        </div>
        <Fcontact />
      </div>
    </div>
  );
};

export default Pailli;
