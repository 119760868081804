import React from "react";
import "./nettoyage.css";
import { Navbar, Fcontact } from "../../components/";

const NettoyageS = () => {
  return (
    <div className="app">
      <Navbar />
      <div className="g__nettoyageS-header">
        <h1 className="g__nettoyage-title">Nettoyage stationnement</h1>
      </div>
      <div className="g__nettoyage-con">
        <div className="g__nettoyage-para1">
          <div style={{ marginBottom: "2rem" }}>
            <span style={{ fontWeight: "800" }}>
              Le nettoyage de stationnement:
            </span>{" "}
            Le nettoyage de stationnement est un processus crucial pour assurer
            la sécurité et la propreté de votre espace extérieur. Chez Gazon
            Écologique, nous comprenons l'importance de maintenir un
            environnement accueillant et dégagé, c'est pourquoi nous offrons un
            service spécialisé de nettoyage de stationnement.
          </div>
          <div style={{ marginBottom: "2rem" }}>
            À l'aide de notre équipement spécialisé, notamment le Duevo 850
            Mini, nous sommes équipés pour éliminer efficacement les roches, le
            gravier, les déchets et les feuilles qui s'accumulent souvent
            pendant la saison hivernale. Notre approche professionnelle garantit
            un nettoyage en profondeur, laissant votre stationnement propre et
            sécuritaire pour vos clients, visiteurs ou résidents.
          </div>

          <div style={{ marginBottom: "2rem" }}>
            Nous comprenons que le nettoyage de stationnement est une étape
            cruciale en début de saison, c'est pourquoi notre équipe s'engage à
            fournir des résultats impeccables rapidement et efficacement. Que ce
            soit à des fins commerciales, résidentielles ou institutionnelles,
            nous avons l'expertise et l'équipement nécessaires pour réaliser un
            nettoyage complet de votre stationnement, de fond en comble.{" "}
          </div>
          <div style={{ marginBottom: "2rem" }}>
            Optez pour la tranquillité d'esprit en confiant le nettoyage de
            votre stationnement à notre entreprise. Contactez-nous dès
            aujourd'hui pour découvrir comment nous pouvons rendre votre
            stationnement impeccable et prêt à accueillir le flux constant de
            visiteurs tout au long de la saison.{" "}
          </div>
          <div style={{ marginBottom: "2rem" }}>
            Gazon Écologique effectue plusieurs types de nettoyage de terrains,
            veuillez nous contacter pour le nettoyage qui vous conviendras. Que
            ce soit à des fins commerciales, résidentielles ou institutionnelle,
            nous serons en mesure de faire un nettoyage impeccable de votre
            stationnement de fond en comble.
          </div>
        </div>
        <Fcontact />
      </div>
    </div>
  );
};

export default NettoyageS;
