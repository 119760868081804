import React from "react";
import "./aeration.css";
import { Navbar, Fcontact } from "../../components/";

const Aeration = () => {
  return (
    <div className="app">
      <Navbar />
      <div className="g__aeration-header">
        <h1 className="g__aeration-title">Aeration de pelouse</h1>
      </div>
      <div className="g__aeration-con">
        <div className="g__aeration-para1">
          <div className="g__aeration-p1">
            La compaction du sol est un problème fréquemment rencontré. En
            effet, la compaction du sol est directement reliée au rapport sol /
            air / eau ce qui la rend déterminante pour la santé du gazon.
          </div>
          <div className="g__aeration-p">
            Le premier élément à considérer afin de procéder à une aération d'un
            sol est de bien apprécier la nature même du sol sur lequel on nous
            demande d'intervenir. En effet, afin de bien connaître la nature
            d'un sol, il faut procéder à la prise d'échantillons à l'aide d'une
            sonde. Également, d'autres indices visuels sont à prendre en
            considération: par exemple, pour les sections ombragées, on
            reconnaîtra un sol compact à la présence de mousse ou de lichens
            alors que pour les sections exposées au soleil, c'est la présence de
            pissenlits, de plantain et de chiendent qui va vous permettre de
            conclure à la présence d'un sol compact.
          </div>
          <h6 className="g__aeration-h6">Autres éléments:</h6>
          <div className="g__aeration-p2" style={{ paddingTop: "0.5rem" }}>
            -<span className="g__aeration-span">La densité du gazon</span> : le
            gazon est-il clairsemé?
          </div>
          <div className="g__aeration-p2">
            -<span className="g__aeration-span">L'historique</span> : le sol
            concerné a t'il déjà été l'objet d'interventions antérieures?
          </div>
          <div className="g__aeration-p2">
            -<span className="g__aeration-span">L'utilisation</span> :
            sommes-nous en présence d'une terrain piétiné?
          </div>
          <h6 className="g__aeration-h6">
            L'aération du sol par carottage favorise:
          </h6>
          <div className="g__aeration-p2" style={{ paddingTop: "0.5rem" }}>
            - Le développement de racines en profondeur d'où un meilleur
            enracinement
          </div>
          <div className="g__aeration-p2">
            - Le développement de nouveaux stolons
          </div>
          <div className="g__aeration-p2">
            - Une densité plus opaque du gazon particulièrement lorsque celui-ci
            est composé principalement de pâturin du Kentucky
          </div>
          <div className="g__aeration-p2">- Un meilleur drainage du sol;</div>
          <div className="g__aeration-p2">
            - Une meilleure absorption des éléments fertilisants par le sol
          </div>
          <div className="g__aeration-p2">
            - Une diminution du chaume sur le sol
          </div>
          <div className="g__aeration-p2">
            - Une stimulation des activités des organismes et des
            micro-organismes qui sont présents dans le sol.
          </div>
          <h6 className="g__aeration-h6">On ne fait pas d'aération du sol:</h6>
          <div className="g__aeration-p2">- En période de sécheresse</div>
          <div className="g__aeration-p2">
            - Lorsque les pissenlits sont en «mousse»
          </div>
          <div className="g__aeration-p2">
            - Lorsqu'il y a beaucoup de fraisiers sauvages
          </div>
          <h6 className="g__aeration-h6">Période de l'année et fréquence</h6>
          <div className="g__aeration-p" style={{ paddingTop: "0.5rem" }}>
            Il y a deux périodes propices dans l'année où l'on peut procéder à
            l'aération du sol; au cours des mois d'avril, de mai et de juin
            ainsi que durant les mois d'août, de septembre et d'octobre. À noter
            que l'aération du sol doit se faire habituellement à tous les deux
            ans. Cependant, en présence d'un sol qui se compacte rapidement, il
            est préférable d'aérer celui-ci à toutes les années.
          </div>
          <div className="g__aeration-p">
            Lorsque vous procédez à un terreautage, l'aération du sol permet une
            meilleure assimilation du terreau dans la pelouse. Lorsque vous
            restaurez une pelouse, l'aération du sol précède l'opération de
            ré-ensemencement et de terreautage.
          </div>
          <h6 className="g__aeration-h6">Conclusion</h6>
          <div className="g__aeration-p" style={{ paddingTop: "0.5rem" }}>
            Les résultats d'une bonne aération du sol permettront à la pelouse
            d'être plus dense et dotée d'une meilleure résistance face aux
            maladies, aux insectes et aux périodes de sécheresse. Enfin, une
            bonne aération du sol sera très efficace en présence d'un sol
            compact, argileux ou limoneux et moins efficace en présence d'un sol
            sablonneux.
          </div>
        </div>
        <Fcontact />
      </div>
    </div>
  );
};

export default Aeration;
