import React from "react";
import "./forfait.css";
import { Navbar } from "../components/";

const Forfait = () => {
  return (
    <div className="app">
      <Navbar />
      <div className="g__forfait-header">
        <h1 className="g__forfait-title">Nos forfaits</h1>
      </div>
      <div className="g__forfait-con">
        <div className="g__forfait1">
          <div>
            <h2 className="g__forfait-item-title">Transition supérieur</h2>
          </div>
          <div className="g__forfait-item-p">
            Ce forfait a été élaboré pour assurer une transition optimale des
            produits chimiques aux produits naturels
          </div>
          <div className="g__forfait-item">Nettoyage printanier</div>
          <div className="g__forfait-item">Aération</div>
          <div className="g__forfait-item">Ensemencement</div>
          <div className="g__forfait-item">Terreautage au compost</div>
          <div className="g__forfait-item">Amendement au compost granulé</div>
          <div className="g__forfait-item">Neutralisation du ph</div>
          <div className="g__forfait-button-con">
            <a href="/contact">
              <button className="g__forfait-button">Contactez-nous!</button>
            </a>
          </div>
        </div>
        <div className="g__forfait1">
          <div>
            <h2 className="g__forfait-item-title">Harmonie</h2>
          </div>
          <div className="g__forfait-item-p">
            Ce forfait est sans contredit le plus populaire
          </div>
          <div className="g__forfait-item">Terreautage au compost</div>
          <div className="g__forfait-item">Fertilisation au gluten de maïs</div>
          <div className="g__forfait-item">Amendement au compost granulé</div>
          <div className="g__forfait-item">Neutralisation du ph</div>
          <div className="g__forfait-button-con">
            <a href="/contact">
              <button className="g__forfait-button">Contactez-nous!</button>
            </a>
          </div>
        </div>
        <div className="g__forfait1">
          <div>
            <h2 className="g__forfait-item-title">Essentiel</h2>
          </div>
          <div className="g__forfait-item-p">
            Ce forfait fournit tous les éléments nutritifs essentiels pour
            maintenir votre pelouse en excellente santé
          </div>
          <div className="g__forfait-item">
            Amendement printanier 100% naturel
          </div>
          <div className="g__forfait-item">
            Fertilisation printanière au gluten de maïs
          </div>
          <div className="g__forfait-item">Amendement au compost granulé</div>
          <div className="g__forfait-item">
            Fertilisation automnale au compost granulé avec sulpomag
          </div>
          <div className="g__forfait-button-con">
            <a href="/contact">
              <button className="g__forfait-button">Contactez-nous!</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forfait;
