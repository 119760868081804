import React, { useEffect } from "react";
import "./app.css";
import { Route, Routes } from "react-router-dom";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

import Home from "./pages/Home";
import Forfait from "./pages/Forfait";
import Propos from "./pages/Propos";
import Contact from "./pages/Contact";

import Aeration from "./pages/services/Aeration";
import Ense from "./pages/services/Ense";
import Epandage from "./pages/services/Epandage";
import Ferti from "./pages/services/Ferti";
import Nettoyage from "./pages/services/Nettoyage";
import Terreau from "./pages/services/Terreau";
import Pailli from "./pages/services/Pailli";
import Autre from "./pages/services/Autre";
import NettoyageS from "./pages/services/NettoyageS";

const TRACKING_ID = "G-7GSV0FGCG4";

const App = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
  }, []);

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <div>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/forfaits" element={<Forfait />} />
        <Route exact path="/a-propos" element={<Propos />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/services/aeration" element={<Aeration />} />
        <Route exact path="/services/ensemencement" element={<Ense />} />
        <Route exact path="/services/epandage" element={<Epandage />} />
        <Route exact path="/services/fertilisation" element={<Ferti />} />
        <Route exact path="/services/nettoyage" element={<Nettoyage />} />
        <Route
          exact
          path="/services/nettoyage-stationnement"
          element={<NettoyageS />}
        />
        <Route exact path="/services/terreautage" element={<Terreau />} />
        <Route exact path="/services/pailli" element={<Pailli />} />
        <Route exact path="/services/autre" element={<Autre />} />
      </Routes>
    </div>
  );
};

export default App;
