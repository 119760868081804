import React from "react";
import "./epandage.css";
import { Navbar, Fcontact } from "../../components/";

const Epandage = () => {
  return (
    <div className="app">
      <Navbar />
      <div className="g__epandage-header">
        <h1 className="g__epandage-title">Épandage de chaux</h1>
      </div>
      <div className="g__epandage-con">
        <div className="g__epandage-para1">
          <div className="g__nettoyage-p1" style={{ marginBottom: "1rem" }}>
            Chez Gazon écologique Inc, nous utilisons une chaux dolomitique.
            Cette chaux pour le gazon est riche en calcium pour éliminer
            l'acidité du sol, ainsi qu'en magnésium pour favoriser la couleur
            verte de la pelouse. Nous disposons d'un outil qui nous permet
            d'évaluer l'acidité du sol.
          </div>
          <div style={{ marginBottom: "1rem" }}>
            L'acidité du sol joue un rôle crucial dans la façon dont l'engrais
            est absorbé ou non. Ainsi, un sol trop acide entrave la bonne
            assimilation des nutriments du gazon, sa croissance saine et sa
            vitalité. En même temps, un sol acide favorise l'apparition et la
            multiplication des mauvaises herbes.
          </div>
          <div style={{ marginBottom: "1rem" }}>
            En conclusion, Le chaulage de la pelouse présente de multiples
            bénéfices. Il est avantageux pour la pelouse d'avoir un niveau
            d'acidité optimal ou un pH neutre, et ce, pour diverses raisons:
          </div>
          <div style={{ marginBottom: "1rem" }}>
            - Améliorer l'efficacité des engrais et des fertilisants. La pelouse
            absorbe efficacement les nutriments lorsque le pH est compris entre
            6 et 7.
          </div>
          <div style={{ marginBottom: "1rem" }}>
            - Améliorer l'activité des micro-organismes présents dans le sol. En
            ayant un pH neutre, les micro-organismes se multiplient et «
            digèrent » en quelque sorte les nutriments, ce qui facilite leur
            assimilation par le gazon.
          </div>
          <div style={{ marginBottom: "1rem" }}>
            - Prendre soin de la couleur et de la densité du gazon. Avec
            l'absorption de vitamines, le gazon devient plus vert et plus dense.
            Le magnésium présent dans la chaux dolomitique joue également un
            rôle dans la végétation de la pelouse.
          </div>
        </div>
        <Fcontact />
      </div>
    </div>
  );
};

export default Epandage;
