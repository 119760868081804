import React from "react";
import "./epandage.css";
import { Navbar, Fcontact } from "../../components/";

const Autre = () => {
  return (
    <div className="app">
      <Navbar />
      <div className="g__autre-header">
        <h1 className="g__epandage-title">Autres Services</h1>
      </div>
      <div className="g__epandage-con">
        <div className="g__epandage-para1">
          <div style={{ marginBottom: "2rem" }}>
            <span style={{ fontWeight: "800" }}>Nivellement de terrain:</span>{" "}
            Niveler un terrain revient à l’égaliser, c’est-à-dire à le rendre
            parfaitement horizontal. Que ce soit pour la construction d'une
            maison, d'une piscine, d'un chemin ou de la plantation d'une
            pelouse, le nivellement du terrain est une étape essentielle à la
            fois pour assurer la durabilité de la construction et pour améliorer
            son aspect esthétique. À l'aide d'un Bobcat Mt100, Gazon Écologique
            est en mesure d'effectuer tout types de mise à niveau selon vos
            besoins.
          </div>
          <div style={{ marginBottom: "2rem" }}>
            <span style={{ fontWeight: "800" }}>Plantation d'arbre:</span> Vous
            souhaitez bénéficier d'un service complet pour vos arbres? Le
            service de plantation proposé par notre entreprise inclut la
            livraison, la plantation, le tuteurage, la fertilisation et la pose
            de paillis nutritifs. Afin de favoriser l'épanouissement optimal de
            votre arbre, nous vous proposons des variétés d'essence qui
            conviendront à vos préférences, qui respecteront votre zone de
            rusticité & la niche écologique dans laquelle il croiteras. Nous
            prendrons également en considération l'exposition au soleil et la
            nature des sols. Nous vous fournirons des informations sur la
            croissance adulte en hauteur et en largeur de la variété choisie,
            ainsi que sur sa capacité à produire des racines. Il est impératif
            qu'elles ne causent aucun dommage aux structures environnantes. Il
            est également possible de recevoir des conseils pour la plantation
            d'arbres fruitiers.
          </div>
          <div>
            <span style={{ fontWeight: "800" }}>Creusement de tranchées:</span>{" "}
            Ce service de creusement de tranchées est effectuée à l'aide d'une
            trancheuse de sol liée hydrauliquement au Bobcat Mt100 super
            compact. Nous effectuons ce service pour diverses raisons, telles
            que l'installation de conduite souterraines, la clôturation du
            terrain, la plantation de haie de cèdre, le drainage des eaux
            pluviales & bien plus. Il nous feras plaisir de vous accompagné dans
            vos petits, moyens & gros travaux.
          </div>
        </div>
        <Fcontact />
      </div>
    </div>
  );
};

export default Autre;
